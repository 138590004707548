import { Injectable } from '@angular/core';
import {
  EventCRUDDataSource,
  EventDateRange,
  EventPublishScopeOptions,
  EventREADDataSource,
  EventScopeOptions,
  EventTypes,
} from './shared-feature-event-data-access';
import { HttpClient } from '@angular/common/http';
import {
  DataSourceMethodsBaseOptions,
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  Deserializer,
} from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { delay, Observable, of } from 'rxjs';
import {
  EventParticipationType,
  EventStatus,
  TremazeEvent,
} from '@tremaze/shared/feature/event/types';
import {
  EventFixtures,
  EventParticipationTypeFixtures,
} from '@tremaze/fixtures';
import { Pagination } from '@tremaze/shared/models';

@Injectable()
export class MockEventREADDataSource extends EventREADDataSource {
  getAllParticipationTypes(): Observable<EventParticipationType[]> {
    return of(EventParticipationTypeFixtures.allParticipationTypes);
  }

  getEventsInDateRange(
    dateRange: EventDateRange,
    types?: EventTypes[],
    onlyHolidayEvents?: boolean,
    options?: DataSourceMethodsPaginatedOptions<any>,
    instIds?: string[],
    departmentIds?: string[],
    userIds?: string[],
    maxResults?: number,
  ): Observable<TremazeEvent[]> {
    return of(
      EventFixtures.generateEventPage({
        filter: {
          page: 0,
          pageSize: 100,
        },
      }).content,
    ).pipe(delay(1));
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<any>,
  ): Observable<TremazeEvent> {
    return of(EventFixtures.generateEvent(1));
  }

  protected deserializer: Deserializer<TremazeEvent>;
  protected js: JsonSerializer;
  protected http: HttpClient;
  protected controller: string;
}

@Injectable()
export class MockEventCRUDDataSource
  extends MockEventREADDataSource
  implements EventCRUDDataSource
{
  getCancellationReasonForEvent(eventId: string): Observable<string> {
    throw new Error('Method not implemented.');
  }
  updateCancellationReasonForEvent(
    eventId: string,
    reason: string,
  ): Observable<void> {
    throw new Error('Method not implemented.');
  }
  deleteById(
    id: string,
    options?: DataSourceMethodsBaseOptions & EventScopeOptions,
    notifyUsers?: boolean,
  ): Observable<boolean> {
    return of(true);
  }

  switchEventPublished(
    event: Pick<TremazeEvent, 'id' | 'published'>,
    options?: EventPublishScopeOptions,
    notifyUsers?: boolean,
  ): Observable<boolean> {
    return of(true);
  }

  updateEventStatus(id: string, status: EventStatus): Observable<void> {
    return of();
  }

  create(
    i: TremazeEvent,
    options?: DataSourceMethodsCreateOptions<TremazeEvent>,
  ): Observable<TremazeEvent> {
    return of(EventFixtures.generateEvent(1));
  }

  edit(
    i: TremazeEvent,
    options?: DataSourceMethodsEditOptions<TremazeEvent>,
  ): Observable<TremazeEvent> {
    return of(i);
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<TremazeEvent>> {
    throw new Error('Method not implemented.');
  }
}

export const provideMockEventREADDataSource = () => ({
  provide: EventREADDataSource,
  useClass: MockEventREADDataSource,
});

export const provideMockEventCRUDDataSource = () => ({
  provide: EventCRUDDataSource,
  useClass: MockEventCRUDDataSource,
});
