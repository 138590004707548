import { BehaviorSubject, Observable, of } from 'rxjs';
import { TenantConfigService } from './tenant-config.service';
import { Injectable } from '@angular/core';
import { FullTenantConfig, TremazeModuleName } from './types';
import { map } from 'rxjs/operators';

@Injectable()
export class MockTenantConfigService implements TenantConfigService {
  readonly _config$ = new BehaviorSubject<FullTenantConfig>({
    disabledFeatures: new Set(),
    enableProfileImages: true,
    enableRegistrationInfo: true,
    addressFieldsRequired: new Set(),
    tremazeCustomer: false,
    isGoalsEnabled: true,
  });

  get config$(): Observable<FullTenantConfig> {
    return this._config$;
  }

  get config(): FullTenantConfig | null {
    return this._config$.value;
  }

  get hasConfig$(): Observable<boolean> {
    return this.config$.pipe(map((config) => !!config));
  }

  get fakeAccountsEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('FAKE_ACCOUNT');
  }

  get fakeAccountsAreDefault$(): Observable<boolean> {
    return of(false);
  }

  get customFormEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('CUSTOM_FORM');
  }

  get workflowEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('WORKFLOW');
  }

  get goalsEnabled$(): Observable<boolean> {
    return this.config$.pipe(map((config) => config.isGoalsEnabled));
  }

  get specializationEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('SPECIALIZATION');
  }

  get additionalInfoEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('ADDITIONAL_INFO');
  }

  get additionalInfo2Enabled$(): Observable<boolean> {
    return this.isModuleEnabled('ADDITIONAL_INFO_2');
  }

  get eventScheduleEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('EVENT_SCHEDULE');
  }

  get postboxEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('POSTBOX');
  }

  get familyEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('FAMILY');
  }

  get contactPointEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('CONTACT_POINT');
  }

  get chatEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('CHAT');
  }

  get dashboardEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('DASHBOARD');
  }

  get eventPresetsEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('EVENT_PRESETS');
  }

  get approvalEnabled$(): Observable<boolean> {
    return this.isModuleEnabled('APPROVAL');
  }

  reload(): void {
    console.log('reload');
  }

  isModuleEnabled(moduleName: TremazeModuleName): Observable<boolean> {
    return this._config$.pipe(
      map((config) => !config.disabledFeatures.has(moduleName)),
    );
  }
}

export const provideMockTenantConfigService = () => ({
  provide: TenantConfigService,
  useClass: MockTenantConfigService,
});
